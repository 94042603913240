<template>
  <div class='users-list'>
    <ipo-loader v-if="showLoader" class="with-margins"/>
    <div v-else>
      <div class="filters" style="margin-bottom: .5rem">
          <b-row>
            <h2 class="sc-title">{{ $t('views.imports.list.exports_list') }}</h2>
            <span class="sort-by-text">{{ $t('views.users.list.sort_by') }}</span>
            <pcg-sort-btn
              class="sort-by my-sort"
              :options="sortOptions"
              v-model="sortBy"
              @input="getFilteredUsers"
            />
          </b-row>
          <b-row align-v="center" style="margin-top: .8rem; margin-bottom: 1rem;" class="normal">
            <label for="fullname" class="filter-users">{{ $t('views.users.list.filter_users') }}</label>
            <pcg-text-input
                id="fullname"
              class="my-text-input"
              style="margin-right: 3rem"
              :show-label="false"
              :placeholder="$t('views.users.list.by_names')"
              v-model="filters.fullName"
              @input="getFilteredUsers"
            />
          </b-row>
          <b-row align-v="center" style="margin-bottom: .7rem">
            <label for="email" class="filter-users">{{ $t('views.users.list.fields.email') }}</label>
            <pcg-text-input
              id="email"
              class="my-text-input"
              style="margin-right: 3rem"
              :show-label="false"
              :placeholder="$t('views.users.list.fields.email')"
              v-model="filters.email"
              @input="getFilteredUsers"
            />
          </b-row>
          <div class="d-flex justify-content-between align-items-center">
              <div class="multi-checkbox-background" style="margin-bottom: .3rem">
                  <pcg-checkbox variant="normal" class="multi-checkbox" @input="setMultiSelect" v-model="multiCheckbox" :ariaText="$t('general.mark')"></pcg-checkbox>
                  <pcg-select :additionalLabel="$t('general.select')"
                    class="multi-select"
                    :options="tDictionary(multiCheckboxOptions)"
                    style="margin:0;margin-bottom:6px;"
                    v-model="multiSelect"
                    @input="setMultiCheckboxes"
                  ></pcg-select>
              </div>
          </div>
        </div>
      <b-table v-if="imports.length > 0"
            class="users-table"
            hover
            thead-class="sthead"
            tbody-tr-class="strow"
            :fields="fields"
            :items="imports"
            :primary-key="imports.id"

      >
        <template v-slot:cell(csv)="data">
          <pcg-checkbox class="my-checkbox authors-checkbox" variant="normal" @input="getImportsIds(data.item.id)" :value="checkedImports.includes(data.item.id) || multiCheckbox" showLabel="false" />
        </template>
        <template v-slot:cell(user)="data">
          <div class="my-text" v-if="data.value">
            <div class="my-text" v-if="data.value && data.value.firstName">
              {{data.value.firstName}} {{data.value.lastName}}
            </div>
            <div class="my-text" v-else>
              {{data.value.email}}
            </div>
          </div>
        </template>
        <template v-slot:cell(importSystem)="data">
          <div class="my-text" v-if="data.value">
            {{ $d(Date.parse(data.value)) }} {{ setTime(data.value) }}
          </div>
        </template>
        <template v-slot:cell(polonExportStart)="data">
          <div class="my-text" v-if="data.value">
            {{ $d(Date.parse(data.value)) }} {{ setTime(data.value) }}
          </div>
        </template>
        <template v-slot:cell(polonExportEnd)="data">
          <div class="my-text" v-if="data.value">
            {{ $d(Date.parse(data.value)) }} {{ setTime(data.value) }}
          </div>
        </template>
        <template v-slot:cell(status)="data">
          <import-status
            :status="data.value"
          />
        </template>
        <template v-slot:cell(iterate)="data">
          <div class="my-text" v-if="data.item.status !== 0 && data.item.status !== 4">
            {{data.item.counter}}
          </div>
        </template>
        <template v-slot:cell(buttons)="data">
          <div class='import-button-group' v-if='data.item.status !== 0 && data.item.status !== 4'>
            <div v-if="data.item.status === 3" class="import-button-group__row">
              <pcg-btn v-if="installationVersion === 1" size="small" variant="additional" @click="showImportStudent(data.item.id)">{{ $t('components.ipo_field.show_students') }}</pcg-btn>
              <div v-else style="width: 200px;"></div>
              <pcg-btn class="remove-btn" size="small" variant="danger" @click="showErrors(data.item.id)">{{ $t('components.ipo_field.show_errors') }}</pcg-btn>
            </div>
            <div v-else-if="data.item.status === 2" class="import-button-group__row">
              <pcg-btn v-if="installationVersion === 1" size="small" variant="additional" @click="showImportStudent(data.item.id)">{{ $t('components.ipo_field.show_students') }}</pcg-btn>
              <div v-else style="width: 200px;"></div>
              <pcg-btn class="remove-btn" size="small" variant="danger" @click="showErrors(data.item.id)">{{ $t('components.ipo_field.show_errors') }}</pcg-btn>
            </div>
            <div v-else-if="data.item.status !== 3 && data.item.status !== 2 && installationVersion === 1" class="import-button-group__row">
              <pcg-btn size="small" variant="additional" @click="showImportStudent(data.item.id)">{{ $t('components.ipo_field.show_students') }}</pcg-btn>
              <pcg-btn size="small" variant="additional" @click="downloadImportJson(data.item.id)">
                <i class='fas fa-download' style="margin-right: 1rem" />
                {{ $t('components.ipo_field.download_json') }}
              </pcg-btn>
            </div>
          </div>
        </template>
        <template #row-details="data">
          <b-row>
            <b-col cols="6">
              <div>
                <span class="row-details-header">ID paczki:</span>
                <span class="row-details-content">{{ data.item.packageId }}</span>
              </div>
            </b-col>
            <b-col cols="6">
              <div>
                <span class="row-details-header">Nazwa importu:</span>
                <span class="row-details-content">{{ data.item.importName }}</span>
              </div>
            </b-col>
          </b-row>
        </template>
      </b-table>
      <pcg-box v-else style="margin-top: 1rem">
        <div class="d-flex justify-content-center no-records">{{ $t('views.imports.list.no_records') }}</div>
      </pcg-box>
      <nav :aria-label="$t('general.pagination')">

        <pcg-pagination
            class="d-flex justify-content-center my-pagination"
            v-model="pagination.page"
            :page-count="pagination.pages"
            :total-records="pagination.count"
            @input="changePage"
        ></pcg-pagination>
      </nav>
      <b-modal ref="show_json"
        class="download-json"
        :hide-header="true"
        :centered="true"
        v-bind:ok-title="$t('general.download_json')"
        ok-variant="primary"
        :ok-only="true"
        :no-close-on-esc="false"
        content-class="my-modal-content"
        footer-class="my-modal-footer"
      >
        <json-prettify
        :json="currentImport.json" />
      </b-modal>
      <b-modal ref="show_errors"
        class="import-errors"
        :title-html="$t('views.students.list.record_errors')"
        dialog-class="show-student-changes"
        :centered="true"
        ok-variant="primary"
        :ok-only="true"
        :no-close-on-esc="false"
        content-class="my-modal-content"
        footer-class="my-modal-footer"
      > 
        <div>
          <div v-if="installationVersion === 1" class='export-errors-buttons'>
            <pcg-btn size="small" :disabled="exportErrorsSpinner" variant="main" @click="exportImportErrors()">
              <span>{{ $t('components.ipo_field.export_to_xlsx') }}</span>
              <spinner v-if="exportErrorsSpinner"></spinner>
            </pcg-btn>
          </div>
          <student-error
          :student-errors="currentImport.errors"
          :all-students-errors-from-import="true"
          />
        </div>
      </b-modal>
    </div>
    <ipo-beam-imports-list style="z-index: 10" v-if="showImportsBeam()" :count="importsCount()"
                                            @cancelMultiCheckbox="cancelMultiCheckbox"
                                            @exportCsv="exportCsv"

      />
  </div>
</template>

<script>

import ApiImports from '../../api/imports'
import NewUserModal from '../../components/NewUserModal'
import translateDictionary from '../../mixins/translate_dictionary'
import IpoLoader from '../../components/IpoLoader'
import ImportStatus from '../../components/importStatus'
import loaderDelay from '../../mixins/loader_delay'
import JsonPrettify from '../../components/JsonPrettify'
import DownloadJson from '../../mixins/download_json'
import DownloadCsv from '../../mixins/download_csv'
import BaseBeam from '../../components/beams/BaseBeam'
import Spinner from '../../components/Spinner'
import { mapGetters } from 'vuex'
import IpoBeamImportsList from '../../components/IpoBeamImportsList'
import StudentError from '../../components/StudentError'
import waitForJobs from '../../mixins/wait_for_jobs'
import Vue from 'vue'
import InstitutionsApi from '../../api/institutions'
import axios from 'axios'

export default {
  name: 'imports-list',
  mixins: [loaderDelay, translateDictionary, DownloadJson, DownloadCsv, waitForJobs],
  components: {
    NewUserModal,
    BaseBeam,
    IpoLoader,
    ImportStatus,
    JsonPrettify,
    IpoBeamImportsList,
    StudentError,
    Spinner
  },
  data: function () {
    return {
      imports: [],
      currentImport:{
        json: {},
        errors: {},
        id: null,
        importSystem: null
      },     
      filters: {
        orderBy: 'import_system',
        orderDirection: 'desc',
        user: '',
        email: ''
      },
      pagination: {
        count: 0,
        items: 0,
        last: 0,
        next: null,
        page: 0,
        pages: 0,
        prev: null
      },
      multiSelect: 'visible',
      multiCheckbox: false,
      resultsCount: 0,
      checkedImports: [],
      uncheckedImports: [],
      selectedAllImports: false,
      perPage: 20,
      sortOptions: [],
      errors: [],
      userErrors: [],
      intervalId: null,
      installationVersion: 0,
      exportToken: null,
      exportErrorsSpinner: false
    }
  },
  mounted () {
    this.$store.dispatch('header/setHeader', {
      title: this.$t('views.imports.list.title'),
      subtitle: this.$t('views.users.list.subtitle'),
      capitalizeSubtitle: false
    })
    this.buildSortOptions()
  },
  watch: {
    '$i18n.locale': function (newLocale, oldLocale) {
      if (oldLocale !== newLocale) {
        this.buildSortOptions()
      }
    }
  },
  created () {
    InstitutionsApi.getInstitutionInformation()
        .then(response => {
          this.installationVersion = response.data.installationVersion
        })
    ApiImports.getImports(this.$route.query)
      .then(result => {
        this.imports = result.data.datas
        this.imports.forEach(si => {
          si._showDetails = true
        })
        this.validRoles = result.data.validRoles
        this.pagination = result.data.pagination
        const currentImportId = result.data.currentImportId
        if (currentImportId) {
          Vue.$cookies.set('current_import_id', currentImportId)
        }
        const query = this.$route.query
        this.perPage = this.pagination.items || 10
        if (!this.checkPerPage()) {
          this.perPage = 10
        }    
        if (Object.prototype.hasOwnProperty.call(query, 'orderBy')) {
          const sort = this.sortBy
          sort.orderBy = query.orderBy
          this.sortBy = sort
        }
      }
    ).finally(() => {
      this.loadingDone = true
      this.startInterval()
    })
  },
  beforeDestroy () {
    this.stopInterval()
  },
  methods: {
    startInterval () {
      this.intervalId = setInterval(this.getIntervalImports, 10000)
    },
    stopInterval () {
      clearInterval(this.intervalId)
    },
    buildSortOptions () {
      this.sortOptions = [
        {
          text: this.$t('views.imports.list.fields.import_date'),
          value: 'import_system'
        },
        {
          text: this.$t('views.imports.list.fields.polon_import_end'),
          value: 'polon_export_end'
        },
        {
          text: this.$t('views.imports.list.fields.polon_import_start'),
          value: 'polon_export_start'
        },
        {
          text: this.$t('views.users.list.surname'),
          value: 'full_name'
        },
      ]
    },
    exportImportErrors() {
      this.exportErrorsSpinner = true
      let query = {}
      query.import_id = this.currentImport.id
      ApiImports.exportImportErrors(query)
      .then((result) => {
        const jobId = result.data.jobId
        this.exportToken = result.data.token
        if (jobId !== undefined) {
          this.waitForJob(jobId, this.ImportErrorsExportSuccess, this.ImportErrorsExportError)
        }
      })
    },
    ImportErrorsExportSuccess() {
      axios({
        url: '/api/v1/imports/download_import_errors',
        params: { token: this.exportToken },
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        let importSystemDate = new Date(this.currentImport.importSystem)
        let formattedDate = new Intl.DateTimeFormat('pl-PL', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        }).format(importSystemDate).replace(/\./g, '_').replace(/, /, ',')
        fileLink.setAttribute('download', `Błędy eksportu-${formattedDate}.zip`)
        document.body.appendChild(fileLink)
        fileLink.click()
        this.$toastr.s(this.$t('views.imports.list.import_errors.import_errors_export_success'))
        this.exportErrorsSpinner = false
      })
    },
    ImportErrorsExportError () {
      this.exportErrorsSpinner = false
      this.$toastr.e(this.$t('views.imports.list.import_errors.import_errors_export_failure'))
    },
    showImportStudent(id) {
      this.$router.push({ name: 'students_list_path', query: {importId: id} })
    },
    repeatExport(id) {
      ApiImports.repeatImport(id).then( (result) => {
        let import_index = this.imports.findIndex(obj => obj.id === id)
        this.imports[import_index].status = result.data.status || 0
        const jobId = result.data.jobId
        if (jobId !== undefined) {
          this.waitForJob(jobId, this.updateImport, this.updateImport)
        }
      }) 
    },
    getImportsIds(id){
      if(this.checkedImports.includes(id)){
        this.checkedImports = this.checkedImports.filter((x) => {return x !== id})
      } else {
        this.checkedImports.push(id)
      }
    },
    cancelMultiCheckbox(){
        this.uncheckedImports = []
        this.checkedImports = []
        this.multiCheckbox = false
        this.multiSelect = 'all'
    },
    exportCsv() {
      let type, imports
      if(this.multiCheckbox) {
        type = this.multiSelect
        imports = []
      } else {
        type = 'selected'
        imports = this.checkedImports
      }
      ApiImports.getImportsInCsv(imports, type, this.filters, this.pagination).then((result) => {
        const currentDate = new Date();
        let formattedDate = currentDate.toLocaleString();
        this.downloadCsv(result.data, `imports_to_csv ${formattedDate}`);
      })
    },
    importsCount(){
      if(this.multiSelect === 'all' && this.multiCheckbox === true){
        return this.pagination.count
      }
      return this.checkedImports.length
    },
    showImportsBeam(){
      return this.checkedImports.length > 0 || this.multiCheckbox
    }, 
    getFilteredUsers () {
      this.pagination.page = 1
      this.getImports()
    },
    getFilters () {
      const oFilters = {}
      Object.keys(this.filters).forEach(key => {
        if (this.filters[key]) {
          oFilters[key] = this.filters[key]
        }
      })
      return oFilters
    },
    getIntervalImports () {
      if (this.$route.name === 'imports_list_path') {
        ApiImports.currentImportId()
            .then(result => {
              if (Vue.$cookies.get('current_import_id')) {
                if (Vue.$cookies.get('current_import_id') !== result.data.newest) {
                  Vue.$cookies.set('current_import_id', result.data.ready)
                  this.getImports()
                }
              } else {
                if (result.data.ready) {
                  Vue.$cookies.set('current_import_id', result.data.ready)
                }
                this.getImports()
              }
            })
            .catch((err) => {
              console.log(err)
            })
      }
    },
    getImports () {
      const filters = this.getFilters()
      let query = {}
      query = filters
      query.page = this.pagination.page
      if (!this.checkPerPage()) {
        this.perPage = 10
      }
      query.per_page = this.perPage
      this.$router.push({ name: 'imports_list_path', query: query })
      ApiImports.getImports(query)
        .then(result => {
          this.addUserSpinner = false
          this.imports = result.data.datas
          this.imports.forEach(si => {
            si._showDetails = true
          })
          this.resultsCount = result.data.results
          this.pagination = result.data.pagination
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updateImport () {
      this.getImports()
    },
    showErrors(id) {
      this.currentImport.errors = {}
      this.currentImport.id = id
      const idx = this.imports.findIndex(i => {
        return i.id === id.toString()
      })
      if (idx >= 0) {
        this.currentImport.importSystem = this.imports[idx].importSystem
      }
       ApiImports.getImportErrors(id).then((result) => {
          const installationVersion = this.installationVersion
          this.currentImport.errors = result.data.map(function(error) {
            if (error['externalId'] && error['externalId'] !== undefined && error['externalId'] !== null) {
              let tmp = error
              error = {}
              if (installationVersion === 1) {
                error.content = `Student ${tmp['externalId']} nie przeszedł walidacji wstępnej: <br><br>` + tmp['errors'].join('<br>')
              } else {
                error.content = `Student ${tmp['externalId']} nie przeszedł walidacji wstępnej.`
              }
              return error
            } else if (error['student'] && error['student'] !== undefined && error['student'] !== null) {
              let tmp = error
              error = {}
              if (installationVersion === 1) {
                error.content = `Student ${tmp['student']} nie przeszedł walidacji POL-on: <br><br>` + tmp['errors'].join('<br>')
              } else {
                error.content = `Student ${tmp['student']} nie przeszedł walidacji POL-on.`
              }
              return error
            }
          })
          this.$refs.show_errors.show()
       })
    },
    downloadImportJson(id) {
      ApiImports.getImportJson(id)
        .then(result => {
          this.downloadJson(result.data, `import_${id}`);
        }
      )
    },
    checkPerPage () {
      return this.perPage === 10 || this.perPage === 25 || this.perPage === 50 || this.perPage === 100
    },
    changePage () {
      if (this.multiCheckbox && this.multiSelect === 'visible') {
        this.multiCheckbox = false
        this.multiSelect = null
        this.checkedImports = []
      }
      this.getImports()
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    },
    setTime (date) {
      const momentDate = moment(date)
      return momentDate.format('LT')
    },
    setMultiSelect () {
      if (this.multiCheckbox === false){
        this.uncheckedImports = []
        this.checkedImports = []
        if (this.multiSelect === null) {
          this.multiSelect = 'all'
        }
      } else {
        this.checkedImports = Array.from(this.imports.map((x) => x.id))
      }

    },
    setMultiCheckboxes () {
      this.uncheckedImports = []
      this.checkedImports = []
      this.multiCheckbox = true
      this.checkedImports = Array.from(this.imports.map((x) => x.id))
    },
  },
  computed: {
    ...mapGetters('auth', ['signedIn', 'currentRole', 'expire']),
    ...mapGetters('page', ['subDomain']),
    sortBy: {
      get () {
        return { orderBy: this.filters.orderBy, orderDirection: this.filters.orderDirection === 'asc' ? 'desc' : 'asc' }
      },
      set (newValue) {
        this.filters.orderBy = newValue.orderBy
        this.filters.orderDirection = newValue.orderDirection === 'asc' ? 'desc' : 'asc'
      }
    },
    multiCheckboxOptions () {
      return [
        { text: 'Wszystko', text_en: 'All', id: 'all' },
        { text: 'Widoczne', text_en: 'Visible', id: 'visible' }
      ]
    },
    fields: function () {
      return [
        {
          key: 'csv',
          label: 'CSV',
          thStyle: 'width: 2rem;'
        },
        {
          key: 'user',
          label: this.$t('views.imports.list.fields.full_name'),
          thStyle: 'min-width: 5rem;'
        },
        {
          key: 'importSystem',
          label: this.$t('views.imports.list.fields.import_date'),
          thStyle: 'min-width: 5rem;'
        },
        {
          key: 'polonExportStart',
          label: this.$t('views.imports.list.fields.polon_import_start'),
          thStyle: 'min-width: 5rem;'
        },
        {
          key: 'polonExportEnd',
          label: this.$t('views.imports.list.fields.polon_import_end'),
          thStyle: ' min-width: 5rem;'
        },
        {
          key: 'status',
          label: this.$t('views.imports.list.fields.import_status'),
          thStyle: 'min-width: rem;'
        },
        {
          key: 'iterate',
          label: 'Licznik',
          thStyle: 'width: 2rem'
        },
        { 
          key: 'buttons',
          label: ''
        }
      ]
    },
    perPageOptions () {
      return [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/stylesheets/vars';

  :deep(.dropdown-menu) {
    overflow: auto;
    max-height: 300px;
  }

  .clicked {
    cursor: pointer;
  }
  .sc-title {
    font-size: 1.71em;
    font-weight: bold;
    line-height: 2rem;
    letter-spacing: .04rem;
    color: $main-color;
    margin-left: .7rem;
    margin-right: 1.5rem;
    text-transform: uppercase;
  }
  :deep(.normal>.checkbox-text) {
    white-space: nowrap;
  }
  .normal > .checkbox-text {
    color: #9d9d9d;
    font-size: .9em;
    font-weight: 400;
    position: relative;
    bottom: .1rem;
    margin-right: .5rem;
  }
  .no-margin {
    :deep(label.pcg-checkbox-container) {
      margin: 0 !important;
    }
    &.form-group {
      margin: 0 !important;
    }
  }

  .users-table {
    margin-top: 1rem;
    background-color: $pcg-white;
  }
  

  :deep(table.users-table) {
    background-color: $pcg-white;
    border-radius: 15px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    min-width: 655px;

    thead {
      background-color: $main-color;
      color: $pcg-white;

      th {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: middle;

        &.td-center {
          text-align: center;
        }
      }

      th:first-child {
        padding-left: 15px;
        border-top-left-radius: 15px;
      }

      th:last-child {
        padding-right: 15px;
        border-top-right-radius: 15px;
      }
    }

    tbody {
      padding-left: 15px;
      padding-right: 15px;
      background-color: $pcg-white;

      td {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: middle;

        .import-status span{
            font-weight: 400;
          a {
            color: unset;
          }
        }
        .import-button-group__row{
          display: flex;
          flex-direction: row;
          align-items: center;
          button{
            width: 180px;
          }
        }

        &.td-center {
          text-align: center;
          color: $main-color;
          font-weight: 400;
        }
      }

      td:first-child {
        padding-left: 15px;
      }

      td:last-child {
        padding-right: 15px;
      }

      tr {
        height: 52px;
      }

      tr:last-child {
        td {
          padding-bottom: 15px;
        }

        td:first-child {
          padding-left: 15px;
          border-bottom-left-radius: 15px;
        }

        td:last-child {
          padding-right: 15px;
          border-bottom-right-radius: 15px;
        }
      }
    }
  }

  .sort-by-text {
    color: $pcg-gray;
    font-weight: 400;
    margin-left: .7rem;
    position: relative;
    top: .3rem;
  }

  .sort-by {
    position: relative;
    top: .4rem;
    margin-left: .7rem;
  }

  .filter-users {
    color: $pcg-gray;
    font-weight: 400;
    margin-left: .7rem;
    margin-right: .8rem;
  }

  .my-text-input {
    margin-right: .8rem;
    margin-left: .7rem;
    position: relative;
    width: 250px;
    top: .3rem;
    :deep(.pcg-field) {
      height: calc(1.5em + 0.75em + 2px);
    }
  }

  .modal-select {
    position: relative;
    top: .3rem;
    :deep(.bootstrap-select .dropdown-toggle) {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
      width: 10rem;
    }
    :deep(.filter-option-inner) {
      position: relative;
      bottom: .45rem;
    }
    :deep(.ls-element .pcg-input){
      height: calc(1.5em + 0.75em + 2px);
    }
  }
  .my-select {
    margin-right: .8rem;
    margin-left: .7rem;
    position: relative;
    width: 250px;
    top: .3rem;
    :deep(.bootstrap-select .dropdown-toggle) {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
    }
    :deep(.filter-option-inner) {
      position: relative;
      bottom: .45rem;
    }
  }

  .my-select-short {
    margin-right: .8rem;
    margin-left: .7rem;
    position: relative;
    width: 100px;
    top: .3rem;
    :deep(.bootstrap-select .dropdown-toggle) {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
    }
    :deep(.filter-option-inner) {
      position: relative;
      bottom: .45rem;
    }
  }

  .per_page {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-s;
  }

  .two-disciplines {
    margin-left: .7rem;
    position: relative;
    top: .3rem;
  }

  .my-checkbox {
      margin-left: 1rem;
      position: relative;
      display: inline;
      top: .7rem;
      font-size: 1.14em;
      :deep(.pcg-checkbox-container){
        margin-right: 0;
        margin-bottom: 0;
      }
      :deep(.label)  {
        margin-bottom: 0;
        transform: translateY(-6px);
      }
    }

  .my-text {
    font-weight: 400;
    color: $main-color;
    a {
      color: unset;
    }
  }

  .no-records {
    color: $pcg-dark-gray;
    font-weight: 400;
    font-size: $font-size-m;
  }

  .my-image {
    width: 20px;
    margin-right: .2rem;
    margin-left: .1rem;
    position: relative;
    bottom: .1rem;
  }

  .pagination-summary {
    color: $pcg-gray !important;
    font-weight: 400 !important;
    font-size: $font-size-s !important;
  }

  .multi-checkbox-background {
      background-color: $pcg-white;
      padding: 0 20px;
      border-radius: 20px;
      display: inline-block;
      margin-right: 2rem;
  }

  .multi-checkbox {
      display: inline-block;
     :deep(.pcg-checkbox-container) {
          margin-bottom: 0;
          margin-right: 0;
          margin-top: .6rem;
      }
  }

  .multi-select {
      display: inline-block;
      margin-top: .6rem;
     :deep(.form-group) {
          margin-bottom: .1rem !important;
      }
     :deep(.bootstrap-select .dropdown-toggle) {
          box-shadow: none;
          background-color: $pcg-white !important;
          padding: 0 .6rem;
          height: 2rem;
      }
     :deep(.filter-option-inner) {
          color: $pcg-white !important;
          width: 0 !important;
      }
     :deep(.btn-light:after) {
          right: .35rem !important;
      }
  }
  .my-page-select {
    margin-right: .8rem;
    margin-left: .7rem;
    position: relative;
    width: 100px;
    display: inline-block;
    :deep(.bootstrap-select .dropdown-toggle) {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
    }
    :deep(.filter-option-inner) {
      position: relative;
      bottom: .45rem;
    }
  }

  .employee-btn {
    margin-bottom: 1rem;
    position: relative;
    bottom: .5rem;
  }

  .my-input {
    :deep(.pcg-input) {
      height: calc(1.5rem + 0.75rem + 2px)
    }
  }

  .my-error {
    color: $pcg-red;
    font-weight: 400;
    margin-left: .3rem;
  }

  .my-exclamation-mark {
    height: 14px;
    width: 14px;
    padding: .2rem .2rem;
    border-radius: 20px;
    text-align: center;
    background-color: $pcg-red;
    opacity: 0.8;
    position: relative;
    bottom: .2rem;
  }

  .confirm-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .required-fields {
    color: $pcg-gray;
    margin-top: 1rem;
    font-weight: 400;
  }

  .edit-role {
    color: $main-active-color;
  }

  .my-header {
    margin-bottom: 2rem;
  }

  .edit-roles {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-right: .5rem;
  }

  .one-role {
    font-weight: 400;
    font-size: $font-size-s;
    color: $pcg-gray;
    position: relative;
    left: .3rem;
  }

  .roles-pen {
    margin-left: .4rem;
    cursor: pointer;
    color: $main-active-color;
  }

  .my-cross {
    color: $pcg-gray;
    position: relative;
    top: .8rem;
    cursor: pointer;
  }

  .user-btn {
    margin-bottom: 1rem;
    position: relative;
    bottom: .5rem;
  }

  .confirm-text {
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1rem;
  }

  .delete-cross {
    cursor: pointer;
    color: $pcg-red;
  }

  .role-name {
    color: $pcg-dark-gray;
    font-weight: 400;
    font-size: $font-size-m;
    padding-left: 1rem;
    padding-top: .5rem;
  }

    :deep(.tooltip-inner) {
      background: $pcg-the-lightest-gray-background !important;
      color: $pcg-gray !important;
      font-weight: 400;
      padding: .5rem;
      border-color: $pcg-light-gray-background;
      border-style: solid;
      border-width: 1px;
      opacity: 1;
    }

    :deep(.arrow::before) {
      border-top-color: $pcg-light-gray-background !important;
    }

  .no-role {
    color: $pcg-orange;
  }
  .my-input {
    :deep(.pcg-input) {
        height: calc(1.5rem + 0.75rem + 2px)
    }
    :deep(.pcg-input-label) {
        margin-bottom: 0.25rem;
        color: $pcg-gray;
      }
    }
  ::v-deep .check-list {
    margin-top: .1rem;
    margin-left: .1rem;
    width: 400px !important;
    @media (max-width: 890px) {
      width: 300px !important;
    }
    @media (max-width: 620px) {
      width: 250px !important;
    }
    @media (max-width: 575.98px) {
      width: 100% !important;
    }
  }

  :deep(.show-student-changes){
    max-width: 700px;
    .modal-header {
      border-bottom: none;
      .modal-title{
        font-size: 1.71em;
        font-weight: bold;
        line-height: 2rem;
        letter-spacing: .04rem;
        color: $main-color;
        margin-left: .7rem;
        margin-right: 1.5rem;
        text-transform: uppercase;
      }
    }
  }

  .row-border {
    border-bottom: 1px solid $pcg-dark-gray;
  }

  .row-details-content {
    font-weight: 500;
    color: $main-color;
  }

  .row-details-header {
    font-weight: 500;
    color: $pcg-dark-gray;
  }
</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    :deep(.show-student-changes){
      .modal-header {
        .modal-title{
          color: $main-color;
        }
      }
    }

    .sc-title {
      color: $main-color;
    }
    .normal > .checkbox-text {
      color: #9d9d9d;
    }
    .sort-by-text {
      color: $pcg-gray;
    }
    .filter-users {
      color: $pcg-gray;
    }
    .my-select {
      :deep(.bootstrap-select .dropdown-toggle) {
        color: $pcg-gray;
      }
    }
    .my-select-short {
      :deep(.bootstrap-select .dropdown-toggle) {
        color: $pcg-gray;
      }
    }
    .per_page {
      color: $pcg-gray;
    }
    .my-text {
      color: $main-color;
    }
    .no-records {
      color: $pcg-dark-gray;
    }
    .pagination-summary {
      color: $pcg-gray !important;
    }
    .my-page-select {
      :deep(.bootstrap-select .dropdown-toggle) {
        color: $pcg-gray;
      }
    }
    .my-sort {
      color: $pcg-gray;
      :deep(.bootstrap-select .dropdown-toggle) {
        color: $main-color !important;
        border: none;
      }
    }
    .my-pagination {
      :deep(.page-item) {
        &, &.disabled {
          .page-link {
            color: $pcg-the-darkest-gray !important;
            &:hover {
              color: $hover-color !important;
            }
          }
        }
        &.active {
          .page-link {
            background-color: $main-active-color !important;
            color: $pcg-white !important;
          }
        }
        &:first-child, &:last-child {
          .page-link {
            color: $main-active-color !important;
          }
        }
      }
    }
    .my-error {
      color: $pcg-red;
    }
    .my-exclamation-mark {
      background-color: $pcg-red;
    }
    .confirm-header {
      color: $pcg-gray;
    }
    .required-fields {
      color: $pcg-gray;
    }
    .roles-pen {
      color: $main-active-color;
    }
    .edit-role {
      color: $main-active-color;
    }
    :deep(.tooltip-inner) {
      background: $pcg-the-lightest-gray !important;
      color: $pcg-gray !important;
      border-color: $pcg-light-gray;
    }

    :deep(.arrow::before){
      border-top-color: $pcg-light-gray !important;
    }

    .row-border {
      border-bottom: 1px solid $pcg-dark-gray;
    }

    .row-details-content {
      font-weight: 500;
      color: $main-color;
    }

    .row-details-header {
      font-weight: 500;
      color: $pcg-dark-gray;
    }
  }

  :deep(.pcg-danger-btn){
    color: #FA4C4D;
    // background: $pcg-white !important;
    border: 1px solid #FA4C4D !important;
  }
  :deep(.export-errors-buttons){
    display: flex;
    justify-content: center;
  }

  .package-id {
    word-break: break-all;
    overflow-wrap: break-word;
    hyphens: auto;
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .users-table {
      background-color: $pcg-white !important;
      thead {
        background-color: $main-color !important;
        color: $pcg-white !important;
      }
      tbody {
        background-color: $pcg-white !important;
        td {
          &.td-center {
            color: $main-color !important;
          }
        }
      }
    }
  }
</style>
